<template>
  <div>
    <Modal :options="{width: '50vw', close: true}" @close="$emit('close')">
        <div class="title">Agregar Comentario</div>
        <div class="body">
            <div class="row form-group">
                <label for="descripcion" class="col-form-label col-sm-4">Agrega un comentario para el cliente</label>
                <div class="col-sm-8">
                  <textarea v-model="comentario" name="descripcion" id="descripcion" cols="30" rows="5" class="form-control"></textarea>
                </div>
            </div>   
        </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="agregar_comentario">Agregar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>

  </div>
</template>

<script>
import Modal from '@/components/Modal';

export default {
    components:{
        Modal
    },
    data(){
        return{
            comentario:null,
        }
    },
    methods:{
        agregar_comentario(){
          if(this.comentario === null || this.comentario === '')
            return this.$helper.showMessage('Error','Debes agregar un comentario','error','alert');

          this.$emit('comentario', this.comentario);
        }
    }
}
</script>

<style>

</style>